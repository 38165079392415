


export const firebaseConfig = {
    apiKey: "AIzaSyDvD3angx5aOYusAAjb9DmI97cDYZIQ09U",
    authDomain: "cyberpunk-3c26d.firebaseapp.com",
    projectId: "cyberpunk-3c26d",
    storageBucket: "cyberpunk-3c26d.appspot.com",
    messagingSenderId: "296679407125",
    appId: "1:296679407125:web:19f540821412076f24581c",
    measurementId: "G-MZM60CXGPV"
}